.basic-camera-container {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.camera-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.camera-preview {
  flex: 1;
  min-height: 400px;
}

.webcam-card {
  height: 100%;
}

.webcam-card .ant-card-body {
  height: calc(100% - 57px);
  display: flex;
  flex-direction: column;
}

.camera-error {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.webcam {
  flex: 1;
  background-color: #000;
  border-radius: 4px;
  overflow: hidden;
}

.camera-controls {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.camera-buttons {
  display: flex;
  gap: 16px;
}

.capture-btn {
  min-width: 120px;
}

.switch-camera-btn {
  min-width: 120px;
}

.photo-actions {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  gap: 16px;
}