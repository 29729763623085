.watermark-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.watermark-image {
  position: relative;
  max-width: 100%;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.main-image {
  display: block;
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
}

.watermark-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 12px;
  font-size: 14px;
}

.watermark-item {
  margin-bottom: 6px;
}

.watermark-label {
  font-weight: bold;
  margin-right: 8px;
}

.location-info, .capture-info {
  display: flex;
  align-items: center;
}

.location-info span:not(.watermark-label),
.capture-info span:not(.watermark-label) {
  flex: 1;
}

.watermark-description {
  display: flex;
  flex-direction: column;
}

.watermark-description .watermark-label {
  margin-bottom: 4px;
}