.camera-container {
  padding: 0px;
}

.camera-content {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

@media (min-width: 992px) {
  .camera-content {
    flex-direction: row;
  }
  
  .camera-preview {
    flex: 1;
  }
  
  .photo-info {
    flex: 1;
  }
}

.webcam-card {
  width: 100%;
  margin-bottom: 20px;
  max-width: none;
}

.webcam {
  width: auto;
  height: 100%;
  aspect-ratio: 3/4;
  border-radius: 4px;
  object-fit: cover;
  display: block;
  max-width: 100%;
  box-sizing: border-box;
  min-height: 400px;
}

.capture-btn {
  margin-top: 16px;
  width: auto;
  padding: 0 24px;
  height: 48px;
  border-radius: 24px;
  font-size: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.capture-btn-s {
  margin-top: 16px;
  min-width: 60px;
  padding: 0 24px;
  height: 48px;
  border-radius: 24px;
  font-size: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.analyzing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  position: relative;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  margin: 10px;
}

.photo-actions {
  display: flex;
  justify-content: space-between;
}

.camera-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 300px; */
  text-align: center;
  padding: 5px;
}

.info-card {
  height: 100%;
}

.ai-result {
  margin-bottom: 16px;
  padding: 5px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.ai-tags {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-item {
  margin-bottom: 16px;
}

.form-item label {
  display: block;
  margin-bottom: 4px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

/* 区域选择样式 */
.location-options {
  margin-bottom: 16px;
  border: 1px solid #f0f0f0;
  padding: 12px;
  border-radius: 4px;
  background-color: #fafafa;
}

.location-options .form-item {
  margin-bottom: 12px;
}

.location-options .form-item:last-child {
  margin-bottom: 0;
}

.camera-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  width: 100%;
}

.switch-camera-btn {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}