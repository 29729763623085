.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 20px;
}

.login-card {
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
}

.login-card .ant-card-head {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  border-bottom: none;
  padding-top: 24px;
}

.login-card .ant-card-body {
  padding: 24px 32px;
}

.login-card .ant-input-affix-wrapper {
  border-radius: 8px;
  height: 42px;
}

.login-card .ant-input-affix-wrapper:hover,
.login-card .ant-input-affix-wrapper:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
}

.login-button {
  height: 42px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 8px;
}

.login-tips {
  margin-top: 24px;
  color: #666;
  font-size: 14px;
  text-align: center;
  background: #f8f9fa;
  padding: 12px;
  border-radius: 8px;
}

.login-tips p {
  margin-bottom: 4px;
}

.login-tips p:last-child {
  margin-bottom: 0;
}