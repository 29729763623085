.settings-container {
  padding: 0;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.ant-table-wrapper {
  margin-bottom: 24px;
  overflow-x: auto;
}

/* 表格内容优化 */
.ant-table-cell {
  word-break: break-word;
}

@media (max-width: 768px) {
  .settings-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .settings-header .ant-btn {
    width: 100%;
  }
  
  .ant-table-cell {
    padding: 12px 8px !important;
  }
  
  /* 移动端表格内容优化 */
  .ant-table-content {
    font-size: 14px;
  }
}