/* 移动端特有样式 */

/* 默认在所有设备上显示 */
.show-on-mobile {
  display: none;
}

/* 移动端显示控制 */
@media (max-width: 767px) {
  .show-on-mobile {
    display: block;
  }
  
  /* 移动端内容区域调整 */
  .responsive-padding {
    padding: 12px !important;
  }
  
  .responsive-content-padding {
    padding: 12px !important;
  }
  
  /* 移动端表格样式调整 */
  .ant-table-wrapper {
    overflow-x: auto;
  }
  
  /* 移动端按钮组样式 */
  .action-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .action-buttons .ant-btn {
    margin-bottom: 8px;
  }
  
  /* 相机页面移动端优化 */
  .camera-content {
    flex-direction: column !important;
    padding: 0 !important;
  }
  
  .webcam-card {
    margin-bottom: 12px !important;
  }
  
  .webcam {
    width: 100% !important;
    aspect-ratio: 4/3 !important;
    max-height: none !important;
  }
  
  .capture-btn {
    height: 44px !important;
    border-radius: 22px !important;
  }
  
  /* 水印组件移动端优化 */
  .watermark-info {
    font-size: 12px !important;
  }
  
  /* 图库页面移动端优化 */
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)) !important;
  }
}