/* MainLayout组件样式 */

.main-header {
  height: auto;
  min-height: 64px;
  line-height: 1.5;
  padding: 12px 16px;
}

/* 响应式调整 */
@media (max-width: 767px) {
  .main-header {
    padding: 8px 12px;
  }
  
  /* 移动端内容区域调整 */
  .responsive-padding {
    padding: 8px !important;
    padding-bottom: 64px !important; /* 为底部导航预留空间 */
  }
  
  .responsive-content-padding {
    padding: 12px !important;
  }
  
  /* 抽屉菜单样式优化 */
  .ant-drawer-content-wrapper {
    width: 80% !important;
    max-width: 300px !important;
  }
  
  .show-on-mobile {
    display: inline-flex;
  }
  
  .hide-on-mobile {
    display: none;
  }
}

/* 桌面端样式优化 */
@media (min-width: 768px) {
  .responsive-padding {
    padding: 16px 24px !important;
  }
  
  .responsive-content-padding {
    padding: 24px !important;
  }
  
  .show-on-mobile {
    display: none;
  }
}

/* 内容区域最大宽度限制 */
.ant-layout-content {
  max-width: 100%;
  overflow: auto;
}

/* 移动端底部导航样式 */
.mobile-bottom-nav {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  box-shadow: 0 -2px 8px rgba(0,0,0,0.05);
  height: 56px;
}

@media (max-width: 767px) {
  .mobile-bottom-nav {
    display: block;
  }
  
  .mobile-nav-container {
    display: flex;
    height: 100%;
  }
  
  .mobile-nav-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.65);
    padding: 4px 0;
    border: none;
    background: transparent;
    height: 100%;
    text-decoration: none;
  }
  
  .mobile-nav-item:hover, .mobile-nav-item:focus {
    color: #1890ff;
    background: transparent;
  }
  
  .mobile-nav-icon {
    font-size: 22px;
    margin-bottom: 2px;
  }
  
  .mobile-nav-label {
    font-size: 12px;
    line-height: 1;
  }
}