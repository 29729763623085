.gallery-container {
  padding: 20px;
  padding-bottom: 40px;
}

.gallery-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.gallery-filters {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 12px;
  margin-top: 20px;
}

.photo-card {
  height: 100%;
  transition: all 0.3s;
  margin-bottom: 0;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.photo-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.photo-card .ant-card-body {
  padding: 8px;
}

.photo-image {
  height: 160px;
  object-fit: cover;
  width: 100%;
  cursor: pointer;
}

.photo-info {
  margin-top: 8px;
}

.photo-metadata p {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 1.3;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.info-label {
  font-weight: 600;
  margin-right: 4px;
}

.photo-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.photo-modal .ant-modal-body {
  padding: 2px;
  overflow-y: auto;
}

.photo-modal .ant-modal-footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #f0f0f0;
  padding-top: 10px;
  gap: 8px;
}

.empty-gallery {
  text-align: center;
  padding: 48px 0;
}

/* Loading indicator styles */
.loading-container {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  align-items: center;
}

.loading-more {
  text-align: center;
  padding: 20px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .gallery-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .gallery-filters {
    flex-direction: column;
    width: 100%;
  }
}

/* Photo detail container */
.photo-detail-container {
  width: 100%;
}

/* Photo info in a single row */
.photo-info-row {
  margin-bottom: 8px;
  padding: 8px;
  background-color: #f9f9f9;
  border-radius: 4px;
  overflow-x: auto;
}

.photo-info-row p {
  margin: 0;
  font-size: 13px;
  line-height: 1.3;
  white-space: normal;
  word-wrap: break-word;
}

.photo-info-row strong {
  font-weight: 600;
  color: #333;
  margin-right: 2px;
  margin-left: 6px;
}

.photo-info-row strong:first-child {
  margin-left: 0;
}

/* Separator between info items */
.separator {
  margin: 0 4px;
}

/* For mobile screens */
@media (max-width: 768px) {
  .photo-info-row p {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .photo-info-row strong {
    margin-left: 0;
    display: inline-block;
  }
  
  .separator {
    display: none;
  }
  
  .photo-comparison {
    flex-direction: column;
  }
}

/* Photo comparison layout */
.photo-comparison {
  display: flex;
  gap: 8px;
  height: calc(100vh - 200px);
  max-height: 500px;
}

.photo-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  border-radius: 4px;
  overflow: hidden;
}

.photo-label {
  background-color: #f0f0f0;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #eee;
}

/* Single photo container */
.photo-single-container {
  height: calc(100vh - 200px);
  max-height: 500px;
  border: 1px solid #eee;
  border-radius: 4px;
  overflow: hidden;
}

/* Photo container for clean display */
.photo-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #f5f5f5;
}

.detail-image, .detail-image-single {
  max-width: 100%;
  /* max-height: 100%; */
  object-fit: contain;
}

/* Download button */
.photo-modal .ant-modal-footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #f0f0f0;
  padding-top: 10px;
  gap: 8px;
}

/* Rectified image (without watermark) */
.rectified-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  border-radius: 4px;
  overflow: hidden;
}

.rectified-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

/* Rectification modal styles */
.rectify-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.original-problem {
  border: 1px solid #eee;
  padding: 15px;
  border-radius: 8px;
  background-color: #fafafa;
}

.original-problem h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.original-image {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.problem-info {
  margin-top: 10px;
}

.problem-info p {
  margin: 5px 0;
}

.rectify-camera-container {
  border: 1px solid #eee;
  padding: 15px;
  border-radius: 8px;
  background-color: #fafafa;
}

.rectify-camera-container h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.webcam-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.webcam {
  width: 100%;
  max-height: 400px;
  border-radius: 4px;
  overflow: hidden;
}

.camera-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.capture-btn {
  min-width: 120px;
}

.camera-error {
  padding: 10px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  margin-bottom: 10px;
}

.rectify-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.preview-image {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 10px;
  background-color: white;
}

.preview-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}