.user-area-settings-container {
  padding: 24px;
  max-width: 800px;
  margin: 0 auto;
}

.settings-card {
  margin-bottom: 24px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.option-tabs {
  margin-top: 24px;
}

.options-container {
  padding: 16px 0;
}

.current-options {
  margin-bottom: 24px;
}

.tags-container {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.option-tag {
  padding: 5px 10px;
  font-size: 14px;
}

.add-option {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.actions {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

/* 移动端适配 */
@media (max-width: 767px) {
  .user-area-settings-container {
    padding: 12px;
  }

  .add-option {
    flex-direction: column;
    align-items: flex-start;
  }

  .add-option button {
    margin-left: 0 !important;
    margin-top: 8px;
    width: 100%;
  }

  .add-option input {
    width: 100% !important;
  }
} 