/* 响应式设计全局样式 */

/* 基础断点定义 */
:root {
  --breakpoint-xs: 480px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

/* 移动优先设计 - 默认样式适用于小屏幕 */

/* 小屏幕设备通用样式 */
@media (max-width: 767px) {
  .hide-on-mobile {
    display: none !important;
  }
  
  /* 调整内边距，在小屏幕上减少空间占用 */
  .ant-layout-content {
    padding: 12px !important;
  }
  
  .ant-card-body {
    padding: 12px !important;
  }
  
  /* 表单元素在移动端的宽度调整 */
  .ant-form-item {
    margin-bottom: 12px !important;
  }
  
  .ant-input, .ant-select, .ant-btn {
    width: 100% !important;
  }
  
  /* 标题文字大小调整 */
  h1.ant-typography {
    font-size: 24px !important;
  }
  
  h2.ant-typography {
    font-size: 20px !important;
  }
  
  h3.ant-typography {
    font-size: 18px !important;
  }
}

/* 响应式布局辅助类 */
.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-column-mobile {
  flex-direction: column;
}

@media (min-width: 768px) {
  .flex-column-mobile {
    flex-direction: row;
  }
}

/* 响应式间距 */
.responsive-gap {
  gap: 8px;
}

@media (min-width: 768px) {
  .responsive-gap {
    gap: 16px;
  }
}

/* 响应式宽度 */
.responsive-width {
  width: 100%;
}

@media (min-width: 768px) {
  .responsive-width {
    width: auto;
  }
}